import { render, staticRenderFns } from "./FailureCheck.vue?vue&type=template&id=00c9dc26&scoped=true&"
var script = {}
import style0 from "./FailureCheck.vue?vue&type=style&index=0&id=00c9dc26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c9dc26",
  null
  
)

export default component.exports